import { ProfileModel } from "../../model/profile.model";
import { OauthModel } from "./oauth.model";
import storage from "./storage";

class TokenService {
    getLocalRefreshToken() {
      const user = JSON.parse(localStorage.getItem(storage.user)!) as OauthModel;
      return user?.refresh_token;
    }
  
    getLocalAccessToken() {
      const user = JSON.parse(localStorage.getItem(storage.user)!) as OauthModel;
      return user?.access_token;
    }
  
    updateLocalAccessToken(token) {
      let user = JSON.parse(localStorage.getItem(storage.user)!) as OauthModel;
      user.access_token = token;
      localStorage.setItem(storage.user, JSON.stringify(user));
    }
    setCode(code) {
      localStorage.setItem(storage.code, code);
    }
    setVerifyCode(code) {
      localStorage.setItem(storage.code_verifier, code);
    }
    getVerifyCode() {
      return localStorage.getItem(storage.code_verifier);
    }
    getCode() {
      return localStorage.getItem(storage.code);
    }
  
    getUser() {
      return JSON.parse(localStorage.getItem(storage.user)!) as OauthModel;
    }
  
    setUser(user) {
      localStorage.setItem(storage.user, JSON.stringify(user));
    }

    setProfile(profile){
      localStorage.setItem(storage.profile_rama, JSON.stringify(profile));
    }
    getProfile() {
      return JSON.parse(localStorage.getItem(storage.profile_rama)!) as ProfileModel;
    }
  
    removeUser() {
      localStorage.removeItem(storage.user);
    }

    clearAll(){
      localStorage.clear();
    }
  }
  
  export default new TokenService();