import { useParams } from "react-router-dom";
import "./resume.scss";
import { useEffect, useState } from "react";
import ApiService from "../../services/api.service";
import { GetResumeIdModel } from "../../model/get-resume.model";
import { GetResumeUrlModel } from "../../model/get-resume-url.model";
import { Container } from "react-bootstrap";
import { Document, Page } from 'react-pdf';
const ResumeComponent = () => {
	const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState(null);
    const { id } = useParams<{ id: string }>();
    const [resumeModel, setResumeModel] = useState<GetResumeUrlModel>();
    useEffect(() => {
        if (id) {
            console.log(id);
            getResume();
        }

    }, [id])
    const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};
    const getResume = () => {

        ApiService().getResumeUrl(id).then((res: GetResumeUrlModel) => {
            if (res) {
                setResumeModel(res);
                console.log(res.resume_url);
                window.open(res.resume_url, '_blank');
            }
        })
    }

    return (

        <>
        <div className="resume">
        {
            resumeModel && <iframe src={resumeModel?.resume_url} className="resume-iframe"></iframe>
        }
        </div>
      
        

        </>
    )
}

export default ResumeComponent;