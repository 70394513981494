const local = {
    ga_4: "G-EYFFS5ZB42",
    ga: "",
    url: "http://localhost:4200/",
    authen: {
        sso_url : "https://d3ia7xx4z5f1n0.cloudfront.net",
        client_id : "LEARN",
        redirect_uri : "http://localhost:4200/checking",
        redirect_uri_interview : "http://localhost:4200/interview-question",
        grant_type : "authorization_code",
        length : 32,
        api: {
            host_url: "https://mysadev.scoutout.net",
            token: "/api/v2.0/oauth/token",
            profile: "/api/v2.0/manager/profile",
            refresh_token: "/api/v2.0/oauth/token"
        },
        rama: {
            host_url: "https://ramadev.scoutout.net",
            create_users: "/api/users",
            profile: "/api/me/profile",
            uploadFile: "/api/me/profile/files"
        }
    },
    upload_resume: {
        x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8",
        base_url : "https://f18w8j76h5.execute-api.ap-southeast-1.amazonaws.com/dev"
    },
    
    chat_gpt: {
        base_url: "https://testlambda.scoutout.net:444/",
        x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8"
    }
};

const dev = {
    ga_4: "G-EYFFS5ZB42",
    ga: "",
    url: "https://resume-builder-test-4c02c.web.app/",
    authen: {
        sso_url : "https://d3ia7xx4z5f1n0.cloudfront.net",
        client_id : "LEARN",
        redirect_uri : "https://hr-clinic-dev.web.app/checking",
        redirect_uri_interview : "https://hr-clinic-dev.web.app",
        grant_type : "authorization_code",
        length : 32,
        api: {
            host_url: "https://mysadev.scoutout.net",
            token: "/api/v2.0/oauth/token",
            profile: "/api/v2.0/manager/profile",
            refresh_token: "/api/v2.0/oauth/token"
        },
        rama: {
            host_url: "https://ramadev.scoutout.net",
            create_users: "/api/users",
            profile: "/api/me/profile",
            uploadFile: "/api/me/profile/files"
        }
    },
    upload_resume: {
        x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8",
        base_url : "https://f18w8j76h5.execute-api.ap-southeast-1.amazonaws.com/dev"
    },
  
    chat_gpt: {
        base_url: "https://testlambda.scoutout.net:444/gpt-interview-question/",
        x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8"
    }
};
const test = {
    ga_4: "G-EYFFS5ZB42",
    ga: "",
    url: "https://resume-builder-test-4c02c.web.app/",
    authen: {
        sso_url : "https://d3ia7xx4z5f1n0.cloudfront.net",
        client_id : "LEARN",
        redirect_uri : "https://scoutconnect-gpt-test.web.app/checking",
        redirect_uri_interview : "https://scoutconnect-gpt-test.web.app/interview-question",
        grant_type : "authorization_code",
        length : 32,
        api: {
            host_url: "https://mysadev.scoutout.net",
            token: "/api/v2.0/oauth/token",
            profile: "/api/v2.0/manager/profile",
            refresh_token: "/api/v2.0/oauth/token"
        },
        rama: {
            host_url: "https://ramadev.scoutout.net",
            create_users: "/api/users",
            profile: "/api/me/profile",
            uploadFile: "/api/me/profile/files"
        }
    },
    upload_resume: {
        x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8",
        base_url : "https://f18w8j76h5.execute-api.ap-southeast-1.amazonaws.com/dev"
    },
    
    chat_gpt: {
        base_url: "https://testlambda.scoutout.net:444/gpt-interview-question/",
        x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8"
    }
   
  
};
const prod = {
    ga_4: "G-E8QW51ZYKK",
    ga: "",
    url: "https://resume-builder.jobhack.co/",
    authen: {
        sso_url : " https://account.scoutout.co",
        client_id : "LEARN",
        redirect_uri : "https://hr9-clinic.scoutout.co/checking",
        redirect_uri_interview : "https://hr9-clinic.scoutout.co/interview-question",
        grant_type : "authorization_code",
        length : 32,
        api: {
            host_url: "https://account-api.scoutoutapi.com",
            token: "/api/v2.0/oauth/token",
            profile: "/api/v2.0/manager/profile",
            refresh_token: "/api/v2.0/oauth/token"
        },
        rama: {
            host_url: "https://career-api.scoutoutapi.com",
            create_users: "/api/users",
            profile: "/api/me/profile",
            uploadFile: "/api/me/profile/files"
        }
    },
    upload_resume: {
        x_api_key: "0kz74WhgVda2JBHCGu7Qd3XSrhgRL5az146oon4w",
        base_url : "https://f3clcckfr7.execute-api.ap-southeast-1.amazonaws.com/production"
    },
   
    chat_gpt: {
        base_url: "https://dprod-api.scoutout.net/generative-ai/",
        x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8"
    }

   
 
    
};

const config = 
    process.env.REACT_APP_ENVIRONMENT === 'production' ? prod : 
    process.env.REACT_APP_ENVIRONMENT === 'local' ? local :
    process.env.REACT_APP_ENVIRONMENT === 'dev' ? dev
        : test;

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};