import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import tokenService from "../../services/sso/token.service";
import ApiService from "../../services/api.service";
import { OauthModel } from "../../services/sso/oauth.model";
import RamaService from "../../services/sso/rama.service";
import { ProvideContext } from "../../context/provide.context";


const CheckingAuth = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();
  
    useEffect(() => {
        if (searchParams.get('code') && searchParams.get('state')) {
            tokenService.setCode(searchParams.get('code') + "");
            getAccessToken(searchParams.get('code') + "", tokenService.getVerifyCode());
        } else if (tokenService.getUser()) {
            console.log(tokenService.getUser());
            getProfile();
        }
    },[])
    const getAccessToken = (code, codeVerifier) => {
        try{
            ApiService().oauthToken(code, codeVerifier).then((res: OauthModel) => {
                if (res) {
                    tokenService.setUser(res);
                    getProfile();
                }else{
                    tokenService.clearAll();
                }
            })
        }catch{
            tokenService.clearAll();
        }

       
    }
    
    const getProfile = () => {
       
        RamaService.getProfile().then((res) => {
            if(res.data){
                tokenService.setProfile(res.data);
                navigate('/');
            }
        }).catch((err) => {
            if(err.response.data.message == "User not found"){
                RamaService.createProfile().then((res) => {
                    getProfile();
                })
            }else{
            }
          
        })
    }

    return (

        <></>
    )
    
}

export default CheckingAuth;