import { FileContent } from "use-file-picker";
import "./template-0.scss";
import { useEffect, useState } from "react";
import { TemplateModel } from "../../../model/template.model";
import PROFILE_MOCK from "../../../assets/images/profile_mock.png";


const Template_0_Component = ({ containerRef, templ }: any) => {
  


    return (

        <>
            {
                templ &&
                <div className="template-0-control">
                    <div className="template-0-form-control" ref={containerRef}>


                        <div className="template-0-left-control" style={{ backgroundColor: templ.color }}>
                            <div className="template-0-profile-control">
                                {templ.profile_img ?
                                    templ.profile_img!.map((file: FileContent, index) => (
                                        <div className="template-0-profile" style={{backgroundImage: 'url('+file.content+')'}}></div>
                                    )) :

                                    <div className="template-0-profile" style={{backgroundImage: 'url('+PROFILE_MOCK+')'}}></div>
                                }
                            </div>
                            <div className="template-0-item-control">
                                <div className="d-flex">
                                    <span className="template-0-title-left">Contact</span>
                                </div>
                                <div className="template-0-divider"></div>
                                <div className="template-0-content-control">
                                    <div className="template-0-content" dangerouslySetInnerHTML={{ __html: templ.contact }}></div>
                                </div>

                            </div>
                            <div className="template-0-item-control">
                                <div className="d-flex">
                                    <span className="template-0-title-left">Expertise</span>
                                </div>
                                <div className="template-0-divider"></div>
                                <div className="template-0-content-control">
                                    <div className="template-0-content" dangerouslySetInnerHTML={{ __html: templ.expertise }}></div>
                                </div>
                            </div>
                            <div className="template-0-item-control">
                                <div className="d-flex">
                                    <span className="template-0-title-left">Language</span>
                                </div>
                                <div className="template-0-divider"></div>
                                <div className="template-0-content-control">
                                    <div className="template-0-content" dangerouslySetInnerHTML={{ __html: templ.language }}></div>

                                </div>

                            </div>
                            <div className="template-0-item-control">
                                <div className="d-flex">
                                    <span className="template-0-title-left">Interest</span>
                                </div>
                                <div className="template-0-divider"></div>
                                <div className="template-0-content-control">
                                    <div className="template-0-content" dangerouslySetInnerHTML={{ __html: templ.interest }}></div>

                                </div>

                            </div>

                        </div>
                        <div className="template-0-right-control">
                            <div className="template-0-name-control">
                                <h1 className="template-0-name"></h1>
                                <div className="template-0-content-control">
                                    <span className="template-0-name" dangerouslySetInnerHTML={{ __html: templ.name }}></span>
                                </div>
                                <div className="template-0-content-control">
                                    <span className="template-0-position" dangerouslySetInnerHTML={{ __html: templ.position }}></span>
                                </div>
                                <div className="template-0-content-control">
                                    <span className="template-0-cover-letter" dangerouslySetInnerHTML={{ __html: templ.cover_letter }}></span>
                                </div>
                                <div className="template-0-exp-control">
                                    <div className="d-flex">
                                        <span className="template-0-exp-title">Experience</span>

                                    </div>
                                    <div className="template-0-exp-divider"></div>
                                    <div className="template-0-content-control">
                                        <div className="template-0-content-black" dangerouslySetInnerHTML={{ __html: templ.experience }}></div>

                                    </div>
                                </div>
                                <div className="template-0-exp-control">
                                    <div className="d-flex">
                                        <span className="template-0-exp-title">Education</span>

                                    </div>

                                    <div className="template-0-exp-divider"></div>
                                    <div className="template-0-content-control">
                                        <div className="template-0-content-black" dangerouslySetInnerHTML={{ __html: templ.education }}></div>

                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            }

        </>
    )
}

export default Template_0_Component;