import React, { useState } from 'react';
import './App.scss';
import { BrowserRouter, Route, Routes, redirect } from "react-router-dom";

import CookieConsent from 'react-cookie-consent';
import { Container } from 'react-bootstrap';
import { ProvideContext } from './context/provide.context';
import LandingComponent from './components/landing/landing';
import BuilderComponent from './components/builder/builder';
import { TemplateModel } from './model/template.model';
import ViewBuilder from './components/view-builder/view-builder';
import ChooseTemplate from './components/choose-template/choose-template';
import ResumeComponent from './components/resume/resume';
import CheckingAuth from './components/checking-auth/checking-auth';
import { ProfileModel } from './model/profile.model';

function App() {
  const [templateModel, setTemplateModel] = useState<TemplateModel>();
  const [currentTemplate, setCurrentTemplate] = useState(0);
  const [profileModel, setProfileModel] = useState<ProfileModel>();
  return (
    <div className="App">
      <BrowserRouter>

        <ProvideContext.Provider
          value={{
           templateModel, setTemplateModel, currentTemplate, setCurrentTemplate, profileModel, setProfileModel
          }}
        >
          <Routes>
         

            <Route path="/" element={<LandingComponent />}  />
            <Route path="/choose-template" element={<ChooseTemplate />}  />
            <Route path="/view" element={<ViewBuilder />}  />
            <Route path='/checking' element={<CheckingAuth />} />
            <Route path="/builder" element={<BuilderComponent />} />
            <Route path="/resume/:id" element={<ResumeComponent />} />
          </Routes>
        </ProvideContext.Provider>
      </BrowserRouter>
      <CookieConsent
        enableDeclineButton
        declineButtonText="ยอมรับเท่าที่จำเป็น"
        location="bottom"
        buttonText="ยอมรับทั้งหมด"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        declineButtonStyle={{ fontSize: "13px", borderRadius: "12px" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px", borderRadius: "12px" }}
        expires={150}
      >
        <Container>


          เว็บไซต์ของเราใช้คุกกี้ เพื่อมอบประสบการณ์ที่ดียิ่งขึ้นแก่คุณ และเพื่อเสนอเนื้อหา
          บริการ และโฆษณาที่คุณอาจสนใจ คลิกดูข้อมูลเพิ่มเติมได้ที่  <a href="https://scoutout.co/terms-th/">เงื่อนไขการใช้บริการ</a>
          และ <a href="https://scoutout.net/privacy-th/">นโยบายความเป็นส่วนตัว</a>

        </Container>

      </CookieConsent>

    </div>
  );
}

export default App;
