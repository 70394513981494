import apiPath from "../api-path"
import config from "../config"
import axios from "axios"


export default function ApiService() {

    return {
        async sugestonPost(desc, path) {
            return fetch(config.chat_gpt.base_url + apiPath.gpt_interview + apiPath.resume + apiPath.generator + path, {
                method: 'POST', headers: {
                    'content-type': 'application/json'
                }, body: JSON.stringify(desc)
            }
            ).then(res => res.json()).catch(err => console.log(err))
        }, async uploadResume(fileUpload, filename) {
            const formData = new FormData();
            formData.append("resume", fileUpload, filename);
            formData.append("type", "resume_builder");
            return axios.post(config.upload_resume.base_url + "/resume/upload", formData, {
                headers: {
                    "x-api-key": config.upload_resume.x_api_key
                }
            })
                .then(res => res.data)
                .catch(errors => errors);
        }, async getResumeId(resumeKey) {
            return fetch(config.chat_gpt.base_url+ apiPath.gpt_interview  + apiPath.resume + apiPath.generator + apiPath.path.upload, {
                method: 'POST', headers: {
                    'content-type': 'application/json'
                }, body: JSON.stringify(resumeKey)
            }
            ).then(res => res.json()).catch(err => console.log(err))

        }, async getResumeUrl(resumeUUID) {
            return fetch(config.chat_gpt.base_url+ apiPath.gpt_interview + apiPath.resume + apiPath.generator + resumeUUID, {
                method: 'GET', headers: {
                    'content-type': 'application/json'
                }
            }
            ).then(res => res.json()).catch(err => console.log(err))

        },
        async oauthToken(code, codeVerifier) {

            return fetch(config.authen.api.host_url + config.authen.api.token, {
                method: 'POST', headers: {
                    'Content-Type': "application/x-www-form-urlencoded",
                }, body: new URLSearchParams({
                    'grant_type': config.authen.grant_type,
                    'code': code,
                    'code_verifier': codeVerifier,
                    'client_id': config.authen.client_id,
                    'redirect_uri': config.authen.redirect_uri
                })
            }
            ).then(res => res.json()).catch(err => console.log(err))
        }
        , async refreshToken(refresh_token) {

            return fetch(config.authen.api.host_url + config.authen.api.token, {
                method: 'POST', headers: {
                    'Content-Type': "application/x-www-form-urlencoded",
                }, body: new URLSearchParams({
                    'grant_type': 'refresh_token',
                    'refresh_token': refresh_token
                })
            }
            ).then(res => res.json()).catch(err => console.log(err))
        },
        async addResume(user_uuid, resumeObject) {
            const resumeJson = {
                "resume_json" : resumeObject
            }
            return fetch(config.chat_gpt.base_url + apiPath.gpt_interview + apiPath.resume + apiPath.generator + apiPath.path.user + "/" + user_uuid, {
                method: 'POST', headers: {
                    'content-type': 'application/json'
                }, body: JSON.stringify(resumeJson)
            }
            ).then(res => res.json()).catch(err => console.log(err))
        },
        async getAllResume(user_uuid) {
           
            return fetch(config.chat_gpt.base_url + apiPath.gpt_interview + apiPath.resume + apiPath.generator + apiPath.path.user + "/" + user_uuid, {
                method: 'GET', headers: {
                    'content-type': 'application/json'
                }
            }
            ).then(res => res.json()).catch(err => console.log(err))
        },
        async getOneResume(user_uuid, resume_uuid) {
           
            return fetch(config.chat_gpt.base_url + apiPath.gpt_interview + apiPath.resume + apiPath.generator + apiPath.path.user + "/" + user_uuid + "/" +apiPath.resume + resume_uuid, {
                method: 'GET', headers: {
                    'content-type': 'application/json'
                }
            }
            ).then(res => res.json()).catch(err => console.log(err))
        },
    }
}