import { Button, Container } from "react-bootstrap";
import ChooseTemplate from "../choose-template/choose-template";
import "./landing.scss";
import FooterComponent from "../footer/footer";
import { useNavigate } from "react-router-dom";
import DESKTOP_IMG from "../../assets/images/desktop.png";
import LIGHT_IMG from "../../assets/images/light.png";
import TAP_IMG from "../../assets/images/tap.png";
import PDF_IMG from "../../assets/images/pdf.png";
import SHARE_IMG from "../../assets/images/share.png";
import HeaderComponent from "../header/header";
import UtilityService from "../../utility/utility.service";
const LandingComponent = () => {

    const navigate = useNavigate();

    const clickStart = () => {
        UtilityService().clickSendEvent("Start build resume", "Click Start build resume")
        navigate('/choose-template')
    }

    return (

        <>
            <div className="landing">
                <HeaderComponent></HeaderComponent>

                <div className="landing-container">
                    <div className="landing-section-1-bg">
                        <Container>
                            <div className="landing-section-1">
                                <h1 className="landing-section-1-header">
                                    สร้างเรซูเม่ ฟรี!

                                </h1>
                                <h3 className="landing-section-1-header">
                                    พร้อมคำแนะนำ <span className="landing-section-1-header-special">ด้วยระบบ AI</span>
                                </h3>

                                <span className="landing-section-1-sub-header">ด้วยเทคโนโลยี AI ที่ทันสมัย คุณจะได้รับเรซูเม่ที่น่าสนใจและตรงกับตำแหน่งงานที่คุณสนใจ พร้อม Template ที่สวยงาม
                                    และคำแนะนำจาก AI จะช่วยให้คุณโดดเด่นและมีโอกาสในการได้รับสิทธิ์ในตำแหน่งงานที่คุณฝันถึง อีกทั้ง คุณสามารถดาวน์โหลด
                                    เรซูเม่ฟรีและแชร์ลิงก์ URL เรซูเม่ในโซเชียลมีเดียเพื่อเพิ่มโอกาสในการหางานอย่างมีประสิทธิภาพได้ด้วย!</span>

                                <Button className="landing-section-1-button" onClick={clickStart}>เริ่มสร้างเรซูเม่</Button>
                            </div>
                        </Container>
                    </div>
                    <div className="landing-section-2">
                        <Container className="landing-section-2-control">
                            <span className="landing-section-2-header-1">
                                ทำไมต้องสร้างเรซูเม่กับเรา?
                            </span>

                            <div className="landing-section-2-grid">
                                <div className="landing-section-2-item">
                                    <img src={DESKTOP_IMG} className="landing-section-2-item-img"></img>
                                    <span className="landing-section-2-item-text-small">เราใช้</span>
                                    <span className="landing-section-2-item-text-title">เทคโนโลยี <span className="landing-section-2-item-text-title-special">AI ที่ล้ำสมัย</span></span>
                                    <span className="landing-section-2-item-text-desc">เราใช้เทคโนโลยีประมวลผลภาษาธรรมชาติระดับสูงที่ให้คำแนะนำที่
                                        เป็นประโยชน์ในการจัดรูปแบบและเน้นข้อมูลสำคัญในเรซูเม่ของคุณทำให้
                                        เรซูเม่ของคุณมีความเป็นเอกลักษณ์และตรงกับตำแหน่งงานที่คุณสนใจ</span>
                                </div>
                                <div className="landing-section-2-item">
                                    <img src={LIGHT_IMG} className="landing-section-2-item-img"></img>
                                    <span className="landing-section-2-item-text-small">เราสร้างสรรค์</span>
                                    <span className="landing-section-2-item-text-title">Template <span className="landing-section-2-item-text-title-special">ที่สวยงาม</span></span>
                                    <span className="landing-section-2-item-text-desc">เรามีเทมเพลตเรซูเม่ที่สวยงามและทันสมัยให้คุณเลือกใช้ คุณสามารถปรับแต่งเทมเพลตตามความต้องการและสไตล์ของคุณได้</span>
                                </div>

                            </div>
                            <div className="landing-section-2-grid">
                                <div className="landing-section-2-item">
                                    <img src={TAP_IMG} className="landing-section-2-item-img"></img>
                                    <span className="landing-section-2-item-text-small">เราส่งเสริม</span>
                                    <span className="landing-section-2-item-text-title">ความสะดวก  <span className="landing-section-2-item-text-title-special">สบาย</span></span>
                                    <span className="landing-section-2-item-text-desc">การสร้างเรซูเม่กับเราเป็นเรื่องง่ายดายไม่จำเป็นต้องมีความเชี่ยวชาญ
                                        ในการออกแบบแค่ไม่กี่คลิกเท่านั้นคุณก็สามารถมีเรซูเม่ที่น่าสนใจได้ทันที</span>
                                </div>
                                <div className="landing-section-2-item">
                                    <img src={PDF_IMG} className="landing-section-2-item-img"></img>
                                    <span className="landing-section-2-item-text-small">เราให้คุณ</span>
                                    <span className="landing-section-2-item-text-title">ดาวน์โหลด  <span className="landing-section-2-item-text-title-special">ฟรี!</span></span>
                                    <span className="landing-section-2-item-text-desc">ไม่มีค่าใช้จ่ายในการดาวน์โหลดเรซูเม่ คุณสามารถนำเรซูเม่
                                        ที่คุณสร้างขึ้น ไปใช้ในการสมัครงานหรือแชร์ให้นายจ้างได้อย่างเสรี</span>
                                </div>

                            </div>
                            <div className="landing-section-2-center">
                                <div className="landing-section-2-item ,x">
                                    <img src={SHARE_IMG} className="landing-section-2-item-img"></img>
                                    <span className="landing-section-2-item-text-small">เรายังสร้าง</span>
                                    <span className="landing-section-2-item-text-title">PUBLIC URL และ<span className="landing-section-2-item-text-title-special">แชร์ไปยัง Social</span></span>
                                    <span className="landing-section-2-item-text-desc">การสร้างเรซูเม่กับเราเป็นเรื่องง่ายดาย ไม่จำเป็นต้องมีความเชี่ยวชาญในการออกแบบ แค่ไม่กี่คลิกเท่านั้นคุณก็สามารถมีเรซูเม่ที่น่าสนใจได้ทันที</span>
                                </div>
                            </div>

                        </Container>
                    </div>
                    <Container>
                        <div className="landing-section-3">
                            <h1 className="landing-section-3-header">
                                ไม่ต้องรอช้า

                            </h1>
                            <Button className="landing-section-3-button" onClick={clickStart}>เริ่มสร้างเรซูเม่</Button>
                            <span className="landing-section-3-sub-header"> ที่น่าสนใจและน่าทึ่งกับเครื่องมือสร้างเรซูเม่ของเราทันที ตั้งแต่เรซูเม่ที่น่าสนใจจนถึงโอกาสในตำแหน่งงานที่คุณปรารถนา!</span>


                        </div>
                    </Container>
                </div>
                <FooterComponent></FooterComponent>
            </div>
            {/* <ChooseTemplate></ChooseTemplate> */}
        </>
    )
}

export default LandingComponent