

const apiPath = {
    resume: "resume/",
    generator: "generate/",
    gpt_interview: "gpt-interview-question/",
    path: {
        cover_letter: "cover_letter",
        skills: "skills",
        education: "education",
        experience: "experience",
        interest: "interest",
        upload: "upload",
        user: "user",

    }
}

export default {
    // Add common config values here
    ...apiPath
};