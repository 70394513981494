import { Button, Container, Form } from "react-bootstrap";
import "./builder.scss";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CircularProgress from '@mui/material/CircularProgress';
import { useContext, useEffect, useRef, useState } from "react";
import CMSModal from "../../modal/cms.modal";
import EDITICON from "../../assets/images/edit.png";
import html2canvas from "html2canvas";
import { useFilePicker } from 'use-file-picker';
import TEMPLATE_0 from "../../assets/json/template/template-0.json";
import TEMPLATE_1 from "../../assets/json/template/template-1.json";
import TEMPLATE_2 from "../../assets/json/template/template-2.json";
import { ProvideContext } from "../../context/provide.context";
import { TemplateModel } from "../../model/template.model";
import { useNavigate } from "react-router-dom";
import ColorPicker from "react-pick-color";
import ColorPickerModal from "../../modal/color-picker.modal";
import inputService from "../../services/input.service";
import Template_0_Component from "../template/0/template-0";
import STAR_IMG from "../../assets/images/stars.png";
import ApiService from "../../services/api.service";
import apiPath from "../../api-path";
import Template_1_Component from "../template/1/template-1";
import Template_2_Component from "../template/2/template-2";
import HeaderComponent from "../header/header";
import UtilityService from "../../utility/utility.service";
const BuilderComponent = () => {
    const navigate = useNavigate();
    const [saveInput, setSaveInput] = useState<TemplateModel>(TEMPLATE_2);
    const [saveInputDefault, setSaveInputDefault] = useState<TemplateModel>(TEMPLATE_2);
    const [color, setColor] = useState(saveInput.color);
    const [isEditColor, setIsEditColor] = useState(false);
    const [isColorOpen, setIsColorOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [tel, setTel] = useState("");
    const [linkedIn, setLinkedIn] = useState("");
    const [line, setLINE] = useState("");
    const { templateModel, setTemplateModel, currentTemplate, setCurrentTemplate, currentKey }: any = useContext(ProvideContext)
    const [contactText, setContactText] = useState(saveInput.contact);
    const [isEditContact, setIsEditContact] = useState(false);
    const [isSaveContact, setIsSaveContact] = useState(false);
    const [educationText, setEducationText] = useState(saveInput.education);
    const [isEditEducation, setIsEditEducation] = useState(false);
    const [isSaveEducation, setIsSaveEducation] = useState(false);
    const [interestText, setInterestText] = useState(saveInput.interest);
    const [isEditInterest, setIsEditInterest] = useState(false);
    const [isSaveInterest, setIsSaveInterest] = useState(false);
    const [isLoadingAI, setIsLoadingAI] = useState(false);
    const [expertiseText, setExpertiseText] = useState(saveInput.expertise);
    const [isEditExpertise, setIsEditExpertise] = useState(false);
    const [isSaveExpertise, setIsSaveExpertise] = useState(false);
    const [languageText, setLanguageText] = useState(saveInput.language);
    const [isEditLanguage, setIsEditLanguage] = useState(false);
    const [isSaveLanguage, setIsSaveLanguage] = useState(false);
    const [nameText, setNameText] = useState(saveInput.name);
    const [isEditName, setIsEditName] = useState(false);
    const [isSaveName, setIsSaveName] = useState(false);
    const [positionText, setPositionText] = useState(saveInput.position);
    const [isEditPosition, setIsEditPosition] = useState(false);
    const [isSavePosition, setIsSavePosition] = useState(false);
    const [coverLetterText, setCoverLetterText] = useState(saveInput.cover_letter);
    const [isEditCoverLetter, setIsEditCoverLetter] = useState(false);
    const [isSaveCoverLetter, setIsSaveCoverLetter] = useState(false);
    const [experienceText, setExperienceText] = useState(saveInput.experience);
    const [isEditExperience, setIsEditExperience] = useState(false);
    const [isSaveExperience, setIsSaveExperience] = useState(false);
    const [isCMSOpen, setIsCMSOpen] = useState(false);
    const [isView, setIsView] = useState(false);
    const [isDownload, setIsDownload] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const [fileUpload, setFileUpload] = useState<any>();

    useEffect(() => {

        if (!inputService.getInput().is_save) {
            if (Number(inputService.getTemplate()) == 0) {
                inputService.setInput(TEMPLATE_0)
            } else if (Number(inputService.getTemplate()) == 1) {
                inputService.setInput(TEMPLATE_1)
            } else if (Number(inputService.getTemplate()) == 2) {
                inputService.setInput(TEMPLATE_2)
            }
            console.log(saveInput)
            setSaveInputDefault(inputService.getInput())
            setSaveInput(inputService.getInput())
        } else {
            console.log(inputService.getInput())
            setSaveInputDefault(inputService.getInput())
            setSaveInput(inputService.getInput())
        }

    }, [currentTemplate])

    useEffect(() => {
        if (saveInput) {
            initalValue();
        }
    }, [saveInput])
    const [openFileSelector, { filesContent, loading, errors }] = useFilePicker({
        multiple: false,
        readAs: 'DataURL',
        accept: ['image/*'],
        limitFilesConfig: { min: 0, max: 1 },
        maxFileSize: 20,
    });
    const initalValue = () => {
        setContactText(saveInput.contact);
        setEducationText(saveInput.education);
        setExperienceText(saveInput.experience);
        setExpertiseText(saveInput.expertise);
        setNameText(saveInput.name);
        setPositionText(saveInput.position);
        setCoverLetterText(saveInput.cover_letter);
        setColor(saveInput.color);
        setLanguageText(saveInput.language);
        setInterestText(saveInput.interest);
    }
    const resetAll = () => {
        setIsEditContact(false);
        setIsEditEducation(false);
        setIsEditExpertise(false);
        setIsEditLanguage(false);
        setIsEditName(false);
        setIsEditPosition(false);
        setIsEditCoverLetter(false);
        setIsEditExperience(false);
        setIsEditColor(false);
        setIsEditInterest(false);
        setIsLoadingAI(false);
    }

    const edit = (listener) => {
        resetAll();
        if (listener == 'contact') {
            if (isEditContact) {
                if (Number(inputService.getTemplate()) == 2) {
                    var contact = "";

                    contact += "Email: " + email + " <br>"
                    contact += "LinkedIn: " + linkedIn + " <br>"
                    contact += "Tel: " + tel + " <br>"
                    contact += "Line: " + line + " <br>"
                    setContactText(contact);
                    changeTextObject('contact', contact)
                    if(saveInputDefault.contact != removeHTMLTags(contact) && contact.length > 5){
                        setIsSaveContact(true);
                    }else{
                        setIsSaveContact(false);
                    }
                } else {
                    changeTextObject('contact', contactText)
                    if(saveInputDefault.contact != removeHTMLTags(contactText)  && contactText.length > 5){
                        setIsSaveContact(true);
                    }else{
                        setIsSaveContact(false);
                    }
                }
               
                setIsEditContact(false);
            } else {
                setIsEditContact(true);
            }
        } else if (listener == 'education') {
            if (isEditEducation) {
                changeTextObject('education', educationText)
                setIsEditEducation(false);
                if(saveInputDefault.education != removeHTMLTags(educationText) && educationText.length > 100){
                    setIsSaveEducation(true);
                }else{
                    setIsSaveEducation(false);
                }
            } else {
                setIsEditEducation(true);
            }
        }
        else if (listener == 'expertise') {
            if (isEditExpertise) {
                changeTextObject('expertise', expertiseText)
                setIsEditExpertise(false);
                if(saveInputDefault.expertise != removeHTMLTags(expertiseText)  && expertiseText.length > 100){
                    setIsSaveExpertise(true);
                }else{
                    setIsSaveExpertise(false);
                }
            } else {
                setIsEditExpertise(true);
            }
        } else if (listener == 'language') {
            if (isEditLanguage) {
                changeTextObject('language', languageText)
                setIsEditLanguage(false);
                if(saveInputDefault.language != removeHTMLTags(languageText)  && languageText.length > 20){
                    setIsSaveLanguage(true);
                }else{
                    setIsSaveLanguage(false);
                }
          
            } else {
                setIsEditLanguage(true);
            }
        } else if (listener == 'name') {
            if (isEditName) {
                console.log(saveInputDefault.name + " === " + removeHTMLTags(nameText));
                changeTextObject('name', nameText)
                setIsEditName(false);
                if(saveInputDefault.name != removeHTMLTags(nameText)  && nameText.length > 20){
                    setIsSaveName(true);
                }else{
                    setIsSaveName(false);
                }
       
            } else {
                setIsEditName(true);
            }


        } else if (listener == 'position') {
            if (isEditPosition) {
                changeTextObject('position', positionText)
                setIsEditPosition(false);
                console.log(positionText);
                if(saveInputDefault.position != removeHTMLTags(positionText)  && positionText.length > 20){
                    setIsSavePosition(true);
                }else{
                    setIsSavePosition(false);
                }
            } else {
                setIsEditPosition(true);
            }
        } else if (listener == 'cover_letter') {
            if (isEditCoverLetter) {
                changeTextObject('cover_letter', coverLetterText)
                setIsEditCoverLetter(false);
                if(saveInputDefault.cover_letter != removeHTMLTags(coverLetterText)  && coverLetterText.length > 100){
                    setIsSaveCoverLetter(true);
                }else{
                    setIsSaveCoverLetter(false);
                }
            } else {
                setIsEditCoverLetter(true);
            }
        } else if (listener == 'experience') {
            if (isEditExperience) {
                changeTextObject('experience', experienceText)
                setIsEditExperience(false);
                if(saveInputDefault.experience != removeHTMLTags(experienceText)  && experienceText.length > 100){
                    setIsSaveExperience(true);
                }else{
                    setIsSaveExperience(false);
                }
            } else {
                setIsEditExperience(true);
            }
        } else if (listener == 'interest') {
            if (isEditInterest) {
                changeTextObject('interest', interestText)
                setIsEditInterest(false);
                if(saveInputDefault.interest != removeHTMLTags(interestText)  && interestText.length > 50){
                    setIsSaveInterest(true);
                }else{
                    setIsSaveInterest(false);
                }
            } else {
                setIsEditInterest(true);
            }
        }
        else if (listener == 'color') {
            setIsEditColor(true);
            setIsColorOpen(true);
        }
    }
    useEffect(() => {
        if (filesContent.length > 0) {
            console.log(filesContent)
            setFileUpload(filesContent[0])
            changeProfile();
        }
    }, [filesContent])

    const clickView = () => {

        let fileImg = filesContent;
        setIsView(true);
        if(isSaveName && isSavePosition && isSaveContact && isSaveCoverLetter && isSaveExpertise && isSaveExperience && isSaveEducation && isSaveLanguage && isSaveInterest){
            const input: TemplateModel = {
                contact: contactText,
                cover_letter: coverLetterText,
                name: nameText,
                position: positionText,
                education: educationText,
                experience: experienceText,
                interest: interestText,
                expertise: expertiseText,
                language: languageText,
                profile_img: fileImg,
                color: color,
                is_save: true,
                is_profile_img: saveInput.is_profile_img
            }
            setIsView(false);
            setSaveInput(input);
            setTemplateModel(input);
            inputService.setInput(input);
            UtilityService().clickSendEvent("View resume", "Click view resume")
            navigate('/view');
        }else{
            
        }

       

    }
    const changeProfile = () => {
        let fileImg = filesContent;
        const updatedObject: TemplateModel = {
            ...saveInput, // Spread the existing properties
            profile_img: fileImg, // Update the specific property
        };
        // Update the state with the new object
        setSaveInput(updatedObject);
    }
    const changeTextObject = (keyObject, value) => {
        const updatedObject = {
            ...saveInput, // Spread the existing properties
            [keyObject]: value, // Update the specific property
        };
        // Update the state with the new object
        setSaveInput(updatedObject);
    }
    const changeColor = (color) => {
        const updatedObject = {
            ...saveInput, // Spread the existing properties
            color: color, // Update the specific property
        };

        // Update the state with the new object
        setSaveInput(updatedObject);
    }

    const clickSuggest = (listener) => {
        setIsLoadingAI(true);
        if (listener == 'cover_letter') {
            const inputObject = {
                cover_letter: coverLetterText
            }
            ApiService().sugestonPost(inputObject, apiPath.path.cover_letter).then((res) => {
                setIsLoadingAI(false);
                setCoverLetterText(res.cover_letter);
            })
        } else if (listener == 'expertise') {
            const inputObject = {
                skills: expertiseText
            }
            ApiService().sugestonPost(inputObject, apiPath.path.skills).then((res) => {
                console.log(res);
                setIsLoadingAI(false);
                setExpertiseText(res.skills);
            })
        } else if (listener == 'education') {
            const inputObject = {
                education: educationText
            }
            ApiService().sugestonPost(inputObject, apiPath.path.education).then((res) => {
                console.log(res);
                setIsLoadingAI(false);
                setEducationText(res.education);
            })
        } else if (listener == 'experience') {
            const inputObject = {
                experience: experienceText
            }
            ApiService().sugestonPost(inputObject, apiPath.path.experience).then((res) => {
                console.log(res);
                setIsLoadingAI(false);
                setExpertiseText(res.experience);
            })
        } else if (listener == 'interest') {
            const inputObject = {
                interest: interestText
            }
            ApiService().sugestonPost(inputObject, apiPath.path.experience).then((res) => {
                console.log(res);
                setIsLoadingAI(false);
                setInterestText(res.interest);
            })
        }
    }



    const changeText = (e, listener) => {
        console.log(e)
        if (listener == 'name') {
            setNameText(e);
        } else if (listener == 'position') {
            setPositionText(e);
        } else if (listener == 'cover_letter') {
            setCoverLetterText(e);
        } else if (listener == 'expertise') {
            setExpertiseText(e);
        } else if (listener == 'language') {
            setLanguageText(e);
        } else if (listener == 'experience') {
            setExperienceText(e);
        } else if (listener == 'education') {
            setEducationText(e);
        } else if (listener == 'contact') {
            setContactText(e);
        } else if (listener == 'interest') {
            setInterestText(e);
        }
    }

    const onContactChange = (e, listener) => {
        var contact = "";
        if (listener == 'email') {
            setEmail(e.target.value);
            contact += "<b>Email:</b> " + e.target.value + "</br>";
        } else if (listener == 'linkedin') {
            setLinkedIn(e.target.value);
            contact += "<b>LinkedIn:</b> " + e.target.value + "</br>";
        } else if (listener == 'line') {
            setLINE(e.target.value);
            contact += "<b>LINE:</b> " + e.target.value + "</br>";
        } else if (listener == 'tel') {
            setTel(e.target.value);
            contact += "<b>Tel:</b> " + e.target.value + "</br>";
        }

        setContactText(contact);


    }

    function removeHTMLTags(htmlString) {
        const regex = /<[^>]*>/g;
        return htmlString.replace(regex, '');
    }
    return (

        <>
            <div className="builder">
                <HeaderComponent></HeaderComponent>
                <Container className="builder-control">
                    <div className="builder-form-control">
                        <div className="builder-form-item-control">
                            <div className="builder-form-item-section">
                                <div className="builder-form-item-title-control">
                                    <span className="builder-form-item-title">เลือก สีของ Template</span>
                                    <span className="builder-form-item-button" onClick={() => edit('color')}>แก้ไข</span>
                                </div>

                                <div className="builder-color-picker" style={{ backgroundColor: color }}></div>
                                <span className="builder-form-item-desc">{color}</span>

                                {
                                    isEditColor && <ColorPickerModal setOpen={setIsColorOpen} setColorMain={changeColor} open={isColorOpen} ></ColorPickerModal>
                                }
                            </div>
                            {
                                saveInput.is_profile_img &&
                                <div className="builder-form-item-section">
                                    <div className="builder-form-item-title-control">
                                        <span className="builder-form-item-title">รูปโปรไฟล์</span>
                                        <span className="builder-form-item-button" onClick={openFileSelector}>แก้ไข</span>
                                    </div>
                                    {filesContent.length > 0 &&


                                        filesContent.map((file, index) => (
                                            <img className="builder-form-item-profile" src={file.content} onClick={openFileSelector}></img>
                                        ))
                                    }
                                </div>
                            }


                            <div className=
                                {
                                    isView && isSaveName ? 
                                    "builder-form-item-section" : isView && !isSaveName 
                                    ? "builder-form-item-section builder-form-item-section-error" : "builder-form-item-section"
                                }>
                                <div className="builder-form-item-title-control">
                                    <span className="builder-form-item-title">Fullname<span className="builder-required-red">*</span></span>

                                    <span className="builder-form-item-button" onClick={() => edit('name')}>
                                        {isEditName ? "บันทึก" : "แก้ไข"}</span>
                                </div>
                                <span className="builder-required-red-gray">Must be at least 20 characters</span>
                                <span className="builder-form-item-desc" dangerouslySetInnerHTML={{ __html: nameText }}></span>
                                {
                                    isEditName &&
                                    <>
                                        <ReactQuill theme="snow" className="builder-cms-background" value={nameText} onChange={(e) => changeText(e, 'name')} />
                                    </>
                                }
                                {/* {
                                    isEditName && <CMSModal open={isCMSOpen} defaultValue={nameText} setOpen={setIsCMSOpen} setText={setNameText}></CMSModal>
                                } */}
                            </div>
                            <div className=
                            {
                                isView && isSavePosition ? 
                                "builder-form-item-section" : isView && !isSavePosition 
                                ? "builder-form-item-section builder-form-item-section-error" : "builder-form-item-section"
                            }>
                                <div className="builder-form-item-title-control">
                                    <span className="builder-form-item-title">Position<span className="builder-required-red">*</span></span>
                                    <span className="builder-form-item-button" onClick={() => edit('position')}>{
                                        isEditPosition ? "บันทึก" : "แก้ไข"
                                    }</span>
                                </div>
                                <span className="builder-required-red-gray">Must be at least 20 characters</span>
                                <span className="builder-form-item-desc" dangerouslySetInnerHTML={{ __html: positionText }}></span>
                                {
                                    isEditPosition && <>
                                        <ReactQuill theme="snow" className="builder-cms-background" value={positionText} onChange={(e) => changeText(e, 'position')} />
                                    </>
                                }
                            </div>
                            <div className= {
                                isView && isSaveCoverLetter ? 
                                "builder-form-item-section" : isView && !isSaveCoverLetter 
                                ? "builder-form-item-section builder-form-item-section-error" : "builder-form-item-section"
                            }>
                                <div className="builder-form-item-title-control">
                                    <span className="builder-form-item-title">Cover Letter<span className="builder-required-red">*</span></span>
                                    <span className="builder-form-item-button" onClick={() => edit('cover_letter')}>{
                                        isEditCoverLetter ? "บันทึก" : "แก้ไข"
                                    }</span>
                                </div>
                                <span className="builder-required-red-gray">Must be at least 100 characters</span>
                                <span className="builder-form-item-desc" dangerouslySetInnerHTML={{ __html: coverLetterText }}></span>
                                {
                                    isEditCoverLetter && <div className="d-flex flex-column">
                                        <div className="builder-form-ai-control" onClick={() => clickSuggest('cover_letter')}>
                                            <img src={STAR_IMG} className="builder-form-ai-img"></img>
                                            <span className="builder-form-ai-text">แก้ไขด้วย AI</span>
                                        </div>
                                        <ReactQuill theme="snow" className="builder-cms-background" value={coverLetterText} onChange={(e) => changeText(e, 'cover_letter')} />
                                    </div>
                                }
                            </div>
                            <div className= {
                                isView && isSaveContact ? 
                                "builder-form-item-section" : isView && !isSaveContact 
                                ? "builder-form-item-section builder-form-item-section-error" : "builder-form-item-section"
                            }>
                                <div className="builder-form-item-title-control">
                                    <span className="builder-form-item-title">Contact<span className="builder-required-red">*</span></span>
                                    <span className="builder-form-item-button" onClick={() => edit('contact')}>{
                                        isEditContact ? "บันทึก" : "แก้ไข"
                                    }</span>
                                </div>
                                <span className="builder-required-red-gray">Must be at least 5 characters</span>
                                <span className="builder-form-item-desc" dangerouslySetInnerHTML={{ __html: contactText }}></span>
                                {
                                    (Number(inputService.getTemplate()) == 2 && isEditContact) ?

                                        <>
                                            <Form>
                                                <span className="builder-form-item-contact-title">Email: {email}</span>
                                                <Form.Control type="text" className="builder-form-item-contact-input" onChange={(e) => onContactChange(e, 'email')}></Form.Control>
                                                <span className="builder-form-item-contact-title">LinkedIn: {linkedIn}</span>
                                                <Form.Control type="text" className="builder-form-item-contact-input" onChange={(e) => onContactChange(e, 'linkedin')}></Form.Control>
                                                <span className="builder-form-item-contact-title">Tel:  {tel}</span>
                                                <Form.Control type="text" className="builder-form-item-contact-input" onChange={(e) => onContactChange(e, 'tel')}></Form.Control>
                                                <span className="builder-form-item-contact-title">LINE:  {line}</span>
                                                <Form.Control type="text" className="builder-form-item-contact-input" onChange={(e) => onContactChange(e, 'line')}></Form.Control>
                                            </Form>
                                        </> : isEditContact && <>
                                            <ReactQuill theme="snow" className="builder-cms-background" value={contactText} onChange={(e) => changeText(e, 'contact')} />
                                        </>
                                }

                            </div>

                            <div className={
                                isView && isSaveExpertise ? 
                                "builder-form-item-section" : isView && !isSaveExpertise 
                                ? "builder-form-item-section builder-form-item-section-error" : "builder-form-item-section"
                            }>
                                <div className="builder-form-item-title-control">
                                    <span className="builder-form-item-title">Expertise<span className="builder-required-red">*</span></span>
                                    <span className="builder-form-item-button" onClick={() => edit('expertise')}>{
                                        isEditExpertise ? "บันทึก" : "แก้ไข"
                                    }</span>
                                </div>
                                <span className="builder-required-red-gray">Must be at least 100 characters</span>
                                <span className="builder-form-item-desc" dangerouslySetInnerHTML={{ __html: expertiseText }}></span>
                                {
                                    isEditExpertise &&
                                    <div className="d-flex flex-column">
                                        <div className="builder-form-ai-control" onClick={() => clickSuggest('expertise')}>

                                            <div className="d-flex">
                                                <img src={STAR_IMG} className="builder-form-ai-img"></img>
                                                <span className="builder-form-ai-text">แก้ไขด้วย AI</span>
                                                {
                                                    isLoadingAI && <CircularProgress className="builder-progress-circle" size={15} />
                                                }

                                            </div>

                                        </div>
                                        <ReactQuill theme="snow" className="builder-cms-background" value={expertiseText} onChange={(e) => changeText(e, 'expertise')} />
                                    </div>
                                }
                            </div>
                            <div className={
                                isView && isSaveLanguage ? 
                                "builder-form-item-section" : isView && !isSaveLanguage 
                                ? "builder-form-item-section builder-form-item-section-error" : "builder-form-item-section"
                            }>
                                <div className="builder-form-item-title-control">
                                    <span className="builder-form-item-title">Language<span className="builder-required-red">*</span></span>
                                    <span className="builder-form-item-button" onClick={() => edit('language')}>{
                                        isEditLanguage ? "บันทึก" : "แก้ไข"
                                    }</span>
                                </div>
                                <span className="builder-required-red-gray">Must be at least 20 characters</span>
                                <span className="builder-form-item-desc" dangerouslySetInnerHTML={{ __html: languageText }}></span>
                                {
                                    isEditLanguage && <>
                                        <ReactQuill theme="snow" className="builder-cms-background" value={languageText} onChange={(e) => changeText(e, 'language')} />
                                    </>
                                }
                            </div>
                            <div className={
                                isView && isSaveInterest ? 
                                "builder-form-item-section" : isView && !isSaveInterest 
                                ? "builder-form-item-section builder-form-item-section-error" : "builder-form-item-section"
                            }>
                                <div className="builder-form-item-title-control">
                                    <span className="builder-form-item-title">Interest<span className="builder-required-red">*</span></span>
                                    <span className="builder-form-item-button" onClick={() => edit('interest')}>{
                                        isEditInterest ? "บันทึก" : "แก้ไข"
                                    }</span>
                                </div>
                                <span className="builder-required-red-gray">Must be at least 50 characters</span>
                                <span className="builder-form-item-desc" dangerouslySetInnerHTML={{ __html: interestText }}></span>
                                {
                                    isEditInterest && <>
                                        <ReactQuill theme="snow" className="builder-cms-background" value={interestText} onChange={(e) => changeText(e, 'interest')} />
                                    </>
                                }
                            </div>
                            <div className={
                                isView && isSaveEducation ? 
                                "builder-form-item-section" : isView && !isSaveEducation 
                                ? "builder-form-item-section builder-form-item-section-error" : "builder-form-item-section"
                            }>
                                <div className="builder-form-item-title-control">
                                    <span className="builder-form-item-title">Education<span className="builder-required-red">*</span></span>
                                    <span className="builder-form-item-button" onClick={() => edit('education')}>{
                                        isEditEducation ? "บันทึก" : "แก้ไข"
                                    }</span>
                                </div>
                                <span className="builder-required-red-gray">Must be at least 100 characters</span>
                                <span className="builder-form-item-desc" dangerouslySetInnerHTML={{ __html: educationText }}></span>
                                {
                                    isEditEducation &&
                                    <div className="d-flex flex-column">
                                        <div className="builder-form-ai-control" onClick={() => clickSuggest('education')}>
                                            <div className="d-flex">
                                                <img src={STAR_IMG} className="builder-form-ai-img"></img>
                                                <span className="builder-form-ai-text">แก้ไขด้วย AI</span>
                                                {
                                                    isLoadingAI && <CircularProgress className="builder-progress-circle" size={15} />
                                                }

                                            </div>

                                        </div>
                                        <ReactQuill theme="snow" className="builder-cms-background" value={educationText} onChange={(e) => changeText(e, 'education')} />
                                    </div>
                                }
                            </div>
                            <div className={
                                isView && isSaveExperience ? 
                                "builder-form-item-section" : isView && !isSaveExperience 
                                ? "builder-form-item-section builder-form-item-section-error" : "builder-form-item-section"
                            }>
                                <div className="builder-form-item-title-control">
                                    <span className="builder-form-item-title">Experience<span className="builder-required-red">*</span></span>
                                    <span className="builder-form-item-button" onClick={() => edit('experience')}>{
                                        isEditExperience ? "บันทึก" : "แก้ไข"
                                    }</span>
                                </div>
                                <span className="builder-required-red-gray">Must be at least 100 characters</span>
                                <span className="builder-form-item-desc" dangerouslySetInnerHTML={{ __html: experienceText }}></span>
                                {
                                    isEditExperience &&
                                    <div className="d-flex flex-column">
                                        <div className="builder-form-ai-control" onClick={() => clickSuggest('experience')}>
                                            <div className="d-flex">
                                                <img src={STAR_IMG} className="builder-form-ai-img"></img>
                                                <span className="builder-form-ai-text">แก้ไขด้วย AI</span>
                                                {
                                                    isLoadingAI && <CircularProgress className="builder-progress-circle" size={15} />
                                                }

                                            </div>

                                        </div>
                                        <ReactQuill theme="snow" className="builder-cms-background" value={experienceText} onChange={(e) => changeText(e, 'experience')} />
                                    </div>
                                }
                            </div>

                        </div>
                        <div className="builder-form-item-control-right">
                            {
                                saveInput &&
                                    Number(inputService.getTemplate()) == 0 ?
                                    <Template_0_Component templ={saveInput} containerRef={containerRef}></Template_0_Component> :
                                    Number(inputService.getTemplate()) == 1 ?
                                        <Template_1_Component templ={saveInput} containerRef={containerRef}></Template_1_Component> :
                                        Number(inputService.getTemplate()) == 2 ?
                                            <Template_2_Component templ={saveInput} containerRef={containerRef}></Template_2_Component>
                                            : <></>
                            }

                        </div>

                    </div>
                    <Button className="builder-preview-button" onClick={clickView}>ดูตัวอย่าง</Button>
                </Container>


            </div>
        </>
    )
}

export default BuilderComponent;