import { Container } from "react-bootstrap";
import "./footer.scss";
import UtilityService from "../../utility/utility.service";
import config from "../../config";

const FooterComponent = () => {

    const clickTerm = (url) => {
        UtilityService().clickSendEvent("Click term & privacy", "Click footer");
        window.open(url, "_blank");
    }

    return (

        <>
            <div className="footer-control">
                <Container className="footer-container">
                    <span className="footer-brand">Resume Builder</span>
                    <span className="footer-term"><span onClick={() => clickTerm("https://scoutout.co/terms-th/")} className="footer-term-a">Terms</span> and <span onClick={() => clickTerm("https://scoutout.co/privacy-th/")} className="footer-term-a">Privacy Policy</span></span>
                </Container>
            </div>
        </>
    )
}

export default FooterComponent;