import { Carousel } from '@trendyol-js/react-carousel';
import TEMPLATE_0_IMG from "../../assets/images/template-0.png";
import TEMPLATE_1_IMG from "../../assets/images/template-1.png";
import TEMPLATE_2_IMG from "../../assets/images/template-2.png";
import { Button, Container } from 'react-bootstrap';
import "./choose-template.scss";
import ARROW_RIGHT from "../../assets/images/arrow-right.png";
import ARROW_LEFT from "../../assets/images/arrow-left.png";
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProvideContext } from '../../context/provide.context';
import inputService from '../../services/input.service';
import TEMPLATE_0 from "../../assets/json/template/template-0.json";
import TEMPLATE_1 from "../../assets/json/template/template-1.json";
import TEMPLATE_2 from "../../assets/json/template/template-2.json";
import HeaderComponent from '../header/header';
import FooterComponent from '../footer/footer';
import UtilityService from '../../utility/utility.service';
const ChooseTemplate = () => {
    const [currentKey, setCurrentKey] = useState(0);
    const { currentTemplate, setCurrentTemplate }: any = useContext(ProvideContext)
    const navigate = useNavigate();

    const onLeftClick = () => {
        setCurrentKey(currentKey - 1)
    }

    const onRightClick = () => {
        setCurrentKey(currentKey + 1)
    }

    const clickChoose = () => {

        if (currentKey == 0) {
            UtilityService().clickSendEvent("Choose Template", "Click template 0")
            setCurrentTemplate(0);
            inputService.setTemplate(0);
            inputService.setInput(TEMPLATE_0)
        } else if (currentKey == 1) {
            UtilityService().clickSendEvent("Choose Template", "Click template 1")
            setCurrentTemplate(1);
            inputService.setTemplate(1);
            inputService.setInput(TEMPLATE_1)
        } else if (currentKey == 2) {
            UtilityService().clickSendEvent("Choose Template", "Click template 2")
            setCurrentTemplate(2);
            inputService.setTemplate(2);
            inputService.setInput(TEMPLATE_2)
        }
        navigate('/builder')
    }

    const chooseTemplate = (index) => {
      
    }

    useEffect(() => {
        console.log(currentTemplate);
    }, [currentTemplate])

    return (

        <>
            <div className="choose-template">
                <HeaderComponent></HeaderComponent>
                <Container className='d-flex flex-column'>
                    <h1 className='choose-template-text'>เลือก Template</h1>
                    <div className="choose-template-control">
                        
                        <Carousel show={1} slide={1} swiping={false} infinite={false} 
                            onLeftArrowClick={onLeftClick} onRightArrowClick={onRightClick}
                            rightArrow={<div className='d-flex h-100'>
                                <img src={ARROW_RIGHT} className='choose-template-arrow' />
                            </div>} leftArrow={<div className='d-flex h-100'>
                                <img src={ARROW_LEFT} className='choose-template-arrow' />
                            </div>}>


                            <div className='choose-template-slide-control'
                            >
                                <img src={TEMPLATE_0_IMG}  className='choose-template-slide'></img>
                            </div>
                            <div className='choose-template-slide-control'>
                                <img src={TEMPLATE_1_IMG}  className='choose-template-slide'></img>
                            </div>
                            <div className='choose-template-slide-control'>
                                <img src={TEMPLATE_2_IMG}  className='choose-template-slide'></img>
                            </div>

                        </Carousel>
                    </div>
                    <Button className='choose-template-button' onClick={clickChoose}>ถัดไป</Button>
                </Container>

            </div>
            <FooterComponent></FooterComponent>
        </>
    )
}

export default ChooseTemplate;