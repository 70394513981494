
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import "./header.scss";
import config from "../../config";
import { useContext, useEffect, useState } from "react";
import UtilityService from "../../utility/utility.service";
import GAConfig from "../../ga.config";
import { useNavigate } from "react-router-dom";
import AuthFunction from "../../services/sso/auth.function";
import storage from "../../services/sso/storage";
import tokenService from "../../services/sso/token.service";
import RamaService from "../../services/sso/rama.service";
import { ProvideContext } from "../../context/provide.context";
import { ProfileModel } from "../../model/profile.model";
import PROFILE_IMG from "../../assets/images/profile_mock.png";
import ApiService from "../../services/api.service";


const HeaderComponent = () => {
    const [expanded, setExpanded] = useState(false);
    const navigate = useNavigate();
    const {profileModel, setProfileModel}: any = useContext(ProvideContext)
    const [profile, setProfile] = useState<ProfileModel>();
    const handleToggle = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        if(tokenService.getProfile()){
            setProfileModel(tokenService.getProfile())
            setProfile(tokenService.getProfile());
            getAllResume(tokenService.getProfile().uuid);
        }
       
       
    },[])


    const clickMenu = (source: string, path) => {
        UtilityService().clickSendEvent(GAConfig.event.click, source)
        window.open(path, "_self");
    }

   

    const authenticateUrl = () => {
            const codeVerifier = AuthFunction().randomString(56);
            const codeChallenge = AuthFunction().PKCEencoding(codeVerifier);
            const state = AuthFunction().randomString(config.authen.length);
            const urlReturn = config.authen.sso_url + "?" + new URLSearchParams({
                redirect_uri: config.authen.redirect_uri,
                state: state,
                code_challenge: codeChallenge,
                response_type: 'code',
                code_challenge_method: 'S256',
                client_id: config.authen.client_id
            })
            tokenService.setVerifyCode(codeVerifier)
            console.log(urlReturn)
            window.open(urlReturn, "_self");
        
    }

    const getAllResume = (uuid) => {
        ApiService().getAllResume(uuid).then((res) => {
            console.log(res);
        })

        // ApiService().getOneResume(uuid,)
    }

    const LogOut = () => {
        tokenService.clearAll();
        window.open('/', '_self')
}

    return (
        <>
            <Navbar expand="lg" expanded={expanded} className='header'>
                <Container className="header-grid-control">
                    <div className="header-logo-control">
                        <span className="header-branding" onClick={() => clickMenu('home', '/')}>Resume Builder</span>
                    </div>

                    <div className="header-menu-center-control">
                        <Nav.Link className="header-menu-a" onClick={() => clickMenu('home', '/')}>Home</Nav.Link>
                        {/* <Nav.Link className="header-menu-a" onClick={() => clickMenu('home', '/')}>Resume Dating</Nav.Link> */}
                        <Nav.Link className="header-menu-a" onClick={() => clickMenu('home', 'https://resume-checker.jobhack.co?utm_source=RESUME_BUILDER')}>Resume Checker</Nav.Link>
                        {/* {
                            profile ? <>
                             <NavDropdown title={
                                            <div className="nav-profile">
                                                <div className="d-flex">
                                                <img src={profile.profileImageUrl ? profile.profileImageUrl : PROFILE_IMG} className="nav-profile-img" ></img>
                                                <span className="nav-name">{profile.firstName}</span>
                                                </div>
                                            </div>
                                        } id="navbarScrollingDropdown" className="nav-profile">
                                            
                                            <NavDropdown.Item href="#action3" className="nav-dropdown-custom" onClick={LogOut}>ออกจากระบบ</NavDropdown.Item>
                                        </NavDropdown>
                            </> :
                            <Nav.Link className="header-menu-a" onClick={authenticateUrl}>สมัครสมาชิก / เข้าสู่ระบบ</Nav.Link>
                        }
                         */}
                    </div>

                    <div className="header-menu-nav-mobile-control">
                        <Navbar.Toggle aria-controls="navbar" onClick={handleToggle} />
                    </div>

                </Container>



                {
                    expanded && <div className="header-navbar-side" >
                       

                        <Nav.Link className="header-navbar-a" onClick={() => clickMenu('home', '/')}>Home</Nav.Link>
                        {/* <Nav.Link className="header-navbar-a" onClick={() => clickMenu('home', '/')}>Resume Dating</Nav.Link> */}
                        <Nav.Link className="header-navbar-a" onClick={() => clickMenu('home', 'https://resume-checker.jobhack.co?utm_source=RESUME_BUILDER')}>Resume Checker</Nav.Link>
                        <Nav.Link className="header-navbar-a" onClick={() => clickMenu('home', "https://scoutout.co/terms-th/")}>Terms & Condition</Nav.Link>
                        <Nav.Link className="header-navbar-a" onClick={() => clickMenu('home', "https://scoutout.co/privacy-th/")}>Privacy Policy</Nav.Link>
                    </div>
                }
            </Navbar>
        </>
    )
}

export default HeaderComponent;