import { FileContent } from "use-file-picker";
import "./template-1.scss";
import { useEffect, useState } from "react";
import { TemplateModel } from "../../../model/template.model";


const Template_1_Component = ({ containerRef, templ }: any) => {


    useEffect(() => {
        console.log(templ);
    },[])

    return (

        <>
            {
                templ &&
                <div className="template-1-control">
                    <div className="template-1-form-control" ref={containerRef}>{
                        <div className="template-1-profile-control" style={{ backgroundColor: templ.color }}>
                            <div className="template-1-profile-text-control">
                                <span className="template-1-name" dangerouslySetInnerHTML={{ __html: templ.name }}></span>
                                <span className="template-1-position" dangerouslySetInnerHTML={{ __html: templ.position }}></span>
                                <span className="template-1-cover-letter" dangerouslySetInnerHTML={{ __html: templ.cover_letter }}></span>
                            </div>
                            <div className="template-1-cover-contact-control">
                                <span className="template-1-cover-contact" dangerouslySetInnerHTML={{ __html: templ.contact }}></span>
                            </div>
                        </div>
                    }

                        <div className="template-1-desc-control">
                            <div className="template-1-desc-left-control">
                                <div className="template-1-desc-left-section-control">
                                    <span className="template-1-desc-title">Skills</span>
                                    <div className="template-1-desc-divider"></div>
                                    <span className="template-1-desc-content" dangerouslySetInnerHTML={{ __html: templ.expertise }}></span>
                                </div>

                                <div className="template-1-desc-left-section-control">
                                    <span className="template-1-desc-title">Language</span>
                                    <div className="template-1-desc-divider"></div>
                                    <span className="template-1-desc-content" dangerouslySetInnerHTML={{ __html: templ.language }}></span>
                                </div>

                                <div className="template-1-desc-left-section-control">
                                    <span className="template-1-desc-title">Interest</span>
                                    <div className="template-1-desc-divider"></div>
                                    <span className="template-1-desc-content" dangerouslySetInnerHTML={{ __html: templ.interest }}></span>
                                </div>
                            </div>
                            <div className="template-1-desc-right-control">
                                <div className="template-1-desc-right-section-control">
                                    <span className="template-1-desc-title">Experiences</span>
                                    <span className="template-1-desc-content" dangerouslySetInnerHTML={{ __html: templ.experience }}></span>
                                </div>
                                <div className="template-1-desc-right-section-control">
                                    <span className="template-1-desc-title">Education</span>
                                    <span className="template-1-desc-content" dangerouslySetInnerHTML={{ __html: templ.education }}></span>
                                </div>
                            </div>

                        </div>
                        <div className="template-1-footer-control" style={{ backgroundColor: templ.color }}></div>
                    </div>
                </div>
            }
        </>
    )
}

export default Template_1_Component;