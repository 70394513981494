import { FileContent } from "use-file-picker";
import "./template-2.scss";
import { useEffect, useState } from "react";
import { TemplateModel } from "../../../model/template.model";


const Template_2_Component = ({ containerRef, templ }: any) => {

    const [email, setEmail] = useState("");
    const [tel, setTel] = useState("");
    const [linkedIn, setLinkedIn] = useState("");
    const [line, setLINE] = useState("");

    useEffect(() => {

        if (templ) {
            if (templ.contact) {
                console.log(templ.contact);
                if (templ.contact.includes('Email')) {
                    var email_start = templ.contact.toString().split("Email: ");
                    console.log(email_start);
                    var email = email_start[1].split("<br>");
                    console.log(email[0]);
                    setEmail(email[0])
                } if (templ.contact.includes('LinkedIn')) {
                    var linkedIn_start = templ.contact.toString().split("LinkedIn: ");
                    var linkedIn = linkedIn_start[1].toString().split("<br>");
                    console.log(linkedIn[0]);
                    setLinkedIn(linkedIn[0])
                } if (templ.contact.includes('Tel')) {
                    var tel_start = templ.contact.toString().split("Tel: ");
                    var tel = tel_start[1].toString().split("<br>");
                    console.log(tel[0]);
                    setTel(tel[0])
                } if (templ.contact.includes('Line')) {
                    var line_start = templ.contact.toString().split("Line: ");
                    var line = line_start[1].toString().split("<br>");
                    console.log(line[0]);
                    setLINE(line[0])
                }

            }
        }

    }, [templ])

    return (

        <>
            {
                templ &&
                <div className="template-2-control">
                    <div className="template-2-form-control" ref={containerRef}>

                        <div className="template-2-profile-control" style={{ backgroundImage: 'linear-gradient(to bottom, '+templ.color+' 50%, white 50%)' }}>
                            <div className="template-2-profile-left-control">
                                {templ.profile_img &&
                                    templ.profile_img!.map((file: FileContent, index) => (
                                        <div className="template-2-profile-img" style={{backgroundImage: 'url('+file.content+')'}}></div>
                                    ))
                                }
                            </div>
                            <div className="template-2-profile-right-control">
                                <div className="template-2-profile-right-top-control">
                                    <span className="template-2-profile-right-top-name" dangerouslySetInnerHTML={{ __html: templ.name }}></span>
                                    <span className="template-2-profile-right-top-position" dangerouslySetInnerHTML={{ __html: templ.position }}></span>
                                </div>
                                <div className="template-2-profile-right-bottom-control">
                                    <div className="template-2-profile-right-bottom-contact-control">
                                        <span className="template-2-profile-right-bottom-contact-title">
                                            Email
                                        </span>
                                        <span className="template-2-profile-right-bottom-contact-text"
                                            dangerouslySetInnerHTML={{ __html: email }}>

                                        </span>
                                    </div>
                                    <div className="template-2-profile-right-bottom-contact-control">
                                        <span className="template-2-profile-right-bottom-contact-title">
                                            LinkedIn
                                        </span>
                                        <span className="template-2-profile-right-bottom-contact-text"
                                            dangerouslySetInnerHTML={{ __html: linkedIn }}>

                                        </span>
                                    </div>
                                    <div className="template-2-profile-right-bottom-contact-control">
                                        <span className="template-2-profile-right-bottom-contact-title">
                                            Tel
                                        </span>
                                        <span className="template-2-profile-right-bottom-contact-text"
                                            dangerouslySetInnerHTML={{ __html: tel }}>

                                        </span>
                                    </div>
                                    <div className="template-2-profile-right-bottom-contact-control">
                                        <span className="template-2-profile-right-bottom-contact-title">
                                            Line
                                        </span>
                                        <span className="template-2-profile-right-bottom-contact-text"
                                            dangerouslySetInnerHTML={{ __html: line }}>

                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="template-2-desc-control">
                            <span className="template-2-desc-about-title">ABOUT ME</span>
                            <span className="template-2-desc-about-desc" dangerouslySetInnerHTML={{ __html: templ.cover_letter }}></span>
                            <div className="template-2-divider"></div>
                            <div className="template-2-desc-grid-control">

                                <div className="template-2-desc-section-control">
                                    <div className="template-2-desc-section-control">
                                        <span className="template-2-desc-title">Skills</span>
                                        <span className="template-2-desc-content" dangerouslySetInnerHTML={{ __html: templ.expertise }}></span>
                                        <div className="template-2-divider"></div>
                                    </div>
                                    <div className="template-2-desc-section-control">
                                        <span className="template-2-desc-title">Language</span>
                                        <span className="template-2-desc-content" dangerouslySetInnerHTML={{ __html: templ.language }}></span>
                                        <div className="template-2-divider"></div>
                                    </div>
                                    <div className="template-2-desc-section-control">
                                        <span className="template-2-desc-title">Interest</span>
                                        <span className="template-2-desc-content" dangerouslySetInnerHTML={{ __html: templ.interest }}></span>
                                    </div>
                                </div>
                                <div className="template-2-desc-section-control">
                                    <div className="template-2-desc-section-control">
                                        <span className="template-2-desc-title">Experience</span>
                                        <span className="template-2-desc-content" dangerouslySetInnerHTML={{ __html: templ.experience }}></span>
                                    </div>
                                    <div className="template-2-desc-section-control">
                                        <span className="template-2-desc-title">Education</span>
                                        <span className="template-2-desc-content" dangerouslySetInnerHTML={{ __html: templ.experience }}></span>
                                    </div>

                                </div>
                            </div>

                        </div>


                    </div>
                    <div className="template-2-footer-control" style={{ backgroundColor: templ.color }}></div>
                </div>
            }
        </>
    )
}

export default Template_2_Component;
