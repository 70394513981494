
import { useContext, useEffect, useRef, useState } from "react";
import "./view-builder.scss";
import { ProvideContext } from "../../context/provide.context";
import { TemplateModel } from "../../model/template.model";
import { FileContent } from "use-file-picker";
import html2canvas from "html2canvas";
import { Button, Container } from "react-bootstrap";
import Template_0_Component from "../template/0/template-0";
import { renderToStaticMarkup, renderToString } from 'react-dom/server';
import html2pdf from 'html2pdf.js';
import jsPDF from 'jspdf';
import { UplaodResumeModel } from "../../model/upload-resume.model";
import ApplyJobModel from "../../model/apply-resume.model";
import ApiService from "../../services/api.service";
import { GetResumeIdModel } from "../../model/get-resume.model";
import config from "../../config";
import inputService from "../../services/input.service";
import Template_1_Component from "../template/1/template-1";
import Template_2_Component from "../template/2/template-2";
import HeaderComponent from "../header/header";
import UtilityService from "../../utility/utility.service";
import { CircularProgress } from "@mui/material";
import { ProfileModel } from "../../model/profile.model";
import tokenService from "../../services/sso/token.service";
const ViewBuilder = () => {
    const { templateModel }: any = useContext(ProvideContext)
    const [templ, setTempl] = useState<TemplateModel>();
    const containerRef = useRef<HTMLDivElement>(null);
    const [isDowload, setIsDownload] = useState(false);
    const [idResume, setIDResume] = useState('');
    const [pdfDataUrl, setPdfDataUrl] = useState('');
    const {profileModel}: any = useContext(ProvideContext)
    const [profile, setProfile] = useState<ProfileModel>();
    useEffect(() => {
        if (templateModel) {
            setTempl(templ);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [templateModel]);

    useEffect(() => {
        if(tokenService.getProfile()){
            setProfile(tokenService.getProfile());
                console.log(templateModel);
                ApiService().addResume(tokenService.getProfile().uuid, templateModel).then((res) => {
                    console.log(res);
                })
        }
       
       
    },[])

    function iOS() {
        return [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      }

    async function convertHtmlToPdfWithResizing(htmlElement: HTMLElement, resizeWidth: number, resizeHeight: number) {
        try {
            const contentHeight = htmlElement.offsetHeight; // Measure the height of the content
            const pdf = new jsPDF('p', 'pt', [htmlElement.offsetWidth, contentHeight]);

            const canvas = await html2canvas(htmlElement, { scale: 5 });
            const imgData = canvas.toDataURL('image/jpeg');

            const pdfWidth = pdf.internal.pageSize.getWidth();
            pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, contentHeight);

            return pdf;
        } catch (error) {
            console.error('Error converting HTML to PDF with resizing:', error);
            throw error;
        }
    }

    const handleDownload = (dataUrl, fileName) => {
        if (dataUrl) {
            const a = document.createElement('a');
            a.href = dataUrl;
            a.download = fileName+'.jpg';
            a.click();
        }
    };

    const download = async () => {
        if (containerRef.current && templateModel) {
            setIsDownload(true);
            const resizeWidth = 2381.10;
            const resizeHeight = 3067.55;

            const pdf = await convertHtmlToPdfWithResizing(containerRef.current, resizeWidth, resizeHeight);

            
            //Save the PDF as a file
            if(!iOS()){
                const file = DataURIToBlob(pdf.output('datauristring'));
                const nameFile = "download " + templateModel.name;
                const stringWithHyphens = nameFile.replace(/\s+/g, '-');
                const uploadResult = await ApiService().uploadResume(file, stringWithHyphens+'.pdf');
                if (uploadResult) {
                    const resumeObj : UplaodResumeModel= {
                        resume_key: uploadResult.resume_key,
                        full_name: templateModel.name,
                        browser: navigator.userAgent
    
                    }
    
                    const resumeGet = await ApiService().getResumeId(resumeObj);
                    if (resumeGet) {
                        console.log(resumeGet);
                        setIsDownload(false);
                        UtilityService().clickSendEvent("download_resume", "Click download resume")
                        const getModel = resumeGet as GetResumeIdModel;
                        setIDResume(getModel.resume_uuid)
                        pdf.save(stringWithHyphens+'.pdf');
                    }
                }
            }else{
                const canvas = await html2canvas(containerRef.current);
                const imgData = canvas.toDataURL('image/jpeg');
                const nameFile = "download " + templateModel.name;
                const stringWithHyphens = nameFile.replace(/\s+/g, '-');
                const file = DataURIToBlob(imgData);
               
                const uploadResult = await ApiService().uploadResume(file, stringWithHyphens+'.jpg');
                if (uploadResult) {
                    const resumeObj : UplaodResumeModel= {
                        resume_key: uploadResult.resume_key,
                        full_name: templateModel.name,
                        browser: navigator.userAgent
                    }
    
                    const resumeGet = await ApiService().getResumeId(resumeObj);
                    if (resumeGet) {
                        setIsDownload(false);
                        UtilityService().clickSendEvent("download_resume", "Click download resume")
                        const getModel = resumeGet as GetResumeIdModel;
                        setIDResume(getModel.resume_uuid)
                        handleDownload(imgData, stringWithHyphens);
                    }
                }
            }
           
           
           


        }

    }
    const DataURIToBlob = (dataURI: string) => {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)
        return new Blob([ia], { type: mimeString })
    }

    const templateFree = () => {

        return (
            Number(inputService.getTemplate()) == 0 ?
                <Template_0_Component templ={templateModel} containerRef={containerRef}></Template_0_Component> :
                Number(inputService.getTemplate()) == 1 ?
                    <Template_1_Component templ={templateModel} containerRef={containerRef}></Template_1_Component> :
                    Number(inputService.getTemplate()) == 2 ?
                        <Template_2_Component templ={templateModel} containerRef={containerRef}></Template_2_Component> :
                        <></>
        )
    }

    const clickOpen = (url) => {
        window.open(url, "_blank");
    }

    return (
        <>
            <div className="d-flex flex-column">
                <HeaderComponent></HeaderComponent>
                <Container className="d-flex flex-column">

                    <div className="view-builder-control">
                        {
                            idResume && <div className="view-builder-text-control">
                                <span className="view-builder-text"> Public URL resume ของคุณคือ </span>
                                <span className="view-builder-text-a" onClick={() => clickOpen(config.url + "resume/" + idResume)}>{config.url}resume/{idResume}</span>
                            </div>

                        }
                        {
                            isDowload ?
                                <div className="view-builder-downloading-control">
                                    <CircularProgress className="view-builder-downloading-progress" size={15} />
                                    <span className="view-builder-downloading-tex">กำลังดาวน์โหลด</span>
                                </div> :
                                !isDowload && !idResume ?
                                    <Button className="view-builder-download" onClick={download}>ดาวน์โหลด</Button> : null
                        }
                        {
                            !idResume && <span className="view-builder-text-consent">
                                การ <strong>ดาวน์โหลด</strong> resume  ระบบจะถือว่าท่านได้เข้าใจและยินยอม <span className="view-builder-text-consent-a">ข้อกำหนด/เงื่อนไขการใช้งาน</span> และ <span className="view-builder-text-consent-a">นโยบายความเป็นส่วนตัว</span>
                            </span>
                        }


                    </div>


                    <div className="view-resume-control">
                        <div className="page-container">
                            {
                                templateModel && templateFree()
                            }
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default ViewBuilder;